@font-face {
	font-family: 'Mundial';
	src: url('Mundial-BlackItalic.woff2') format('woff2'),
		url('Mundial-BlackItalic.woff') format('woff');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Mundial';
	src: url('Mundial-Black.woff2') format('woff2'),
		url('Mundial-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Mundial';
	src: url('Mundial-Bold.woff2') format('woff2'),
		url('Mundial-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Mundial';
	src: url('Mundial-DemiboldItalic.woff2') format('woff2'),
		url('Mundial-DemiboldItalic.woff') format('woff');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Mundial';
	src: url('Mundial-BoldItalic.woff2') format('woff2'),
		url('Mundial-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Mundial';
	src: url('Mundial-Demibold.woff2') format('woff2'),
		url('Mundial-Demibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Mundial';
	src: url('Mundial-Italic.woff2') format('woff2'),
		url('Mundial-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Mundial';
	src: url('Mundial-HairItalic.woff2') format('woff2'),
		url('Mundial-HairItalic.woff') format('woff');
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Mundial';
	src: url('Mundial-Hair.woff2') format('woff2'),
		url('Mundial-Hair.woff') format('woff');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Mundial';
	src: url('Mundial-Light.woff2') format('woff2'),
		url('Mundial-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Mundial';
	src: url('Mundial-LightItalic.woff2') format('woff2'),
		url('Mundial-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Mundial';
	src: url('Mundial-Thin.woff2') format('woff2'),
		url('Mundial-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Mundial';
	src: url('Mundial-Regular.woff2') format('woff2'),
		url('Mundial-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Mundial';
	src: url('Mundial-ThinItalic.woff2') format('woff2'),
		url('Mundial-ThinItalic.woff') format('woff');
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}
