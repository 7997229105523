html {
	scroll-behavior: smooth;
}

body {
	font-family: 'Mundial', sans-serif;
	overflow-x: hidden !important;
	max-width: 100%;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.text-gray {
	color: #6d6d6d;
}

.bg-gray {
	background-color: #f0f0f0;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.lock-animation {
	position: fixed;
	object-fit: contain;
	margin: 0 auto;
	transition: 350ms ease-in;
	z-index: 50;
}

canvas {
	object-fit: cover;
}

/* .isometric {
	width: 80vw;
	object-fit: cover;
} */

.trailsText {
	position: relative;
	width: auto;
	height: 80px;
	line-height: 80px;
	color: black;
	font-size: 4.5em;
	font-weight: 200;
	letter-spacing: -0.05em;
	white-space: nowrap;
	will-change: transform, opacity, height;
	overflow: hidden;
}

.trailsText > div {
	padding-right: 0.05em;
	overflow: hidden;
}

.mouse-icon {
	border: 2px solid #fff;
	border-radius: 16px;
	height: 40px;
	width: 24px;
	display: fixed;
	z-index: 10;
	opacity: 0.7;
	position: fixed;
}
.mouse-icon .wheel {
	-webkit-animation-name: drop;
	-webkit-animation-duration: 1s;
	-webkit-animation-timing-function: linear;
	-webkit-animation-delay: 0s;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-play-state: running;
	-webkit-animation-name: drop;
	animation-name: drop;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}
.mouse-icon .wheel {
	position: relative;
	border-radius: 10px;
	background: #fff;
	width: 2px;
	height: 6px;
	top: 4px;
	margin-left: auto;
	margin-right: auto;
	display: fixed;
	z-index: 20;
}
@-webkit-keyframes drop {
	0% {
		top: 5px;
		opacity: 0;
	}
	30% {
		top: 10px;
		opacity: 1;
	}
	100% {
		top: 25px;
		opacity: 0;
	}
}
@keyframes drop {
	0% {
		top: 5px;
		opacity: 0;
	}
	30% {
		top: 10px;
		opacity: 1;
	}
	100% {
		top: 25px;
		opacity: 0;
	}
}

@media (min-width: 768px) {
	.interiorImg {
		height: 55vh;
	}
}

@media (max-width: 640px) {
	.interiorImg {
		height: 30vh;
	}

	.trailsText {
		font-size: 2.3em;
	}
}

.scrollHorizontal {
	cursor: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3e%3cpath stroke-linecap='round' stroke-linejoin='round' d='M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5' /%3e%3c/svg%3e"),
		auto;
}

.customCurs {
	cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 4.5l15 15m0 0V8.25m0 11.25H8.25' /%3E%3C/svg%3E%0A")
			32 32,
		pointer;
}

.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background-color: #000;
	opacity: 0.5;
}

.modal-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1050;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	outline: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal {
	z-index: 100;
	background: white;
	position: relative;

	margin: 10% auto;
	border-radius: 3px;
	max-width: 750px;
	padding: 2rem;
}

.modal-header {
	display: flex;
	justify-content: flex-end;
}

.modal-close-button {
	font-size: 1.4rem;
	font-weight: 700;
	line-height: 1;
	color: #000;
	opacity: 0.3;
	cursor: pointer;
	border: none;
}
